import { createSelector } from 'reselect'
import { Map, fromJS } from 'immutable'

const selectUserProfileViewDomain = state =>
  state !== undefined ? state.get('userProfileView', Map()) : fromJS({})

// export const selectUser = createSelector(
//   selectUserProfileViewDomain,
//   data => data.get('user') || Map()
// )

export const selectFetchingUser = createSelector(
  selectUserProfileViewDomain,
  data => data.get('fetchingUser')
)

export const selectResettingPassword = createSelector(
  selectUserProfileViewDomain,
  data => data.get('resettingPassword')
)

export const selectTogglingUser = createSelector(
  selectUserProfileViewDomain,
  data => data.get('togglingUser')
)
