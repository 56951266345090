import { takeLatest, put } from 'redux-saga/effects'

// import { getClient } from 'apolloClient'
// import gql from 'graphql-tag'
// import _ from 'lodash'

import { RESET_PASSWORD } from './constants'
import { resetPasswordSuccess } from './actions'

function* resetPassword() {
  // const RESET_PASSWORD_MUTATION = `
  //   put a query in here pal
  // `

  try {
    // const client = getClient()
    // const data = yield client.query({
    //   query: gql`
    //     ${RESET_PASSWORD_MUTATION}
    //   `,
    //   variables: {
    //     srn: action.payload.srn,
    //   },
    // })
    const data = yield new Promise(resolve => {
      setTimeout(() => {
        resolve({ done: true })
      }, 1000)
    })
    if (data) {
      yield put(resetPasswordSuccess())
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* userProfileViewSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

export default userProfileViewSaga
