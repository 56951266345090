import { defineMessages } from 'react-intl'

export default defineMessages({
  EnableUser: {
    id: 'app.containers.UserProfileView.enableUser',
    defaultMessage: 'Enable User',
  },
  DisableUser: {
    id: 'app.containers.UserProfileView.disableUser',
    defaultMessage: 'Disable User',
  },
  ResetPassword: {
    id: 'app.containers.UserProfileView.resetPasssword',
    defaultMessage: 'Reset Password',
  },
  RoleTitle: {
    id: 'app.containers.UserProfileView.roleTitle',
    defaultMessage: 'Roles for ',
  },
  UnassignRole: {
    id: 'app.containers.UserProfileView.unassignRole',
    defaultMessage: 'Unassign Role(s)',
  },
  OpenModal: {
    id: 'app.containers.UserProfileView.openModal',
    defaultMessage: 'Manage Roles',
  },
})
