import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from './constants'

const initialState = fromJS({
  togglingUser: false,
  unassigningRole: false,
})

const userProfileViewReducer = handleActions(
  {
    [RESET_PASSWORD]: state => state.set('resettingPassword', true),
    [RESET_PASSWORD_SUCCESS]: state => state.set('resettingPassword', false),
  },
  initialState
)

export default userProfileViewReducer
