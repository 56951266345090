import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import qs from 'query-string'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'

import messages from './messages'
import { Helmet } from 'react-helmet'
import { ToolbarItem } from 'components/ActionToolbar'
import ProfileIcon from 'components/ProfileIcon'
import BorderedCard from 'components/BorderedCard'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import userProfileViewSaga from './sagas'
import userProfileViewReducer from './reducer'
import color from 'color'
import { resetPassword } from './actions'
import { updateUserRoles } from 'containers/SonraiData/actions'
import { selectResettingPassword, selectTogglingUser } from './selectors'
import {
  selectUpdatingUserRoles,
  selectUnassigningRoles,
  selectSonraiUsers,
  selectSonraiUsersLoading,
  selectUpdatingSonraiUsers,
  selectAllRoles,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { updateSonraiUser } from 'containers/SonraiData/actions'
import { List, fromJS } from 'immutable'
import permissionChecker from 'containers/PermissionChecker'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import Icon from 'components/Icon'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import SectionHeader from 'components/SectionHeader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import InlineEditableText from 'components/InlineEditableText'
import AddEditInviteModal from 'containers/UserManagement/AddEditInviteModal'
import { FriendlyScope } from 'components/RolesDisplay'

export class UserProfileView extends React.Component {
  styles = {
    container: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr',
      padding: '1rem',
      overflow: 'auto',
    },
    breadcrumbs: {
      fontSize: '1rem',
      margin: '0rem 0rem 0.5rem 0rem',
    },
    header: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      columnGap: '0.5em',
      marginBottom: '0.5em',
    },
    actionButton: {
      display: 'grid',
      gridTemplateColumns: '1fr 15px',
      columnGap: '0.35em',
    },
    name: {
      fontSize: '1.5rem',
      marginTop: '-0.3rem',
    },
    email: {
      fontSize: '1rem',
      marginTop: '-0.5rem',
    },
    button: {
      height: '1.85rem',
      fontSize: '0.85rem',
      border: `1px solid ${this.props.theme.primary}`,
      padding: '0.25rem 0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: '5px',
      color: color(this.props.theme.neutralDark).darken(0.6),
    },
    input: {
      paddingLeft: '0.3em',
      width: '100%',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      alignItems: 'center',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      selectedSrns: List(),
      selectedRows: [],
      isModalOpen: false,
    }
  }

  componentWillUnmount() {
    this.gridApi = null
  }

  openRoleModal = () => {
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  onTableLoad = ({ api }) => {
    this.gridApi = api
  }

  parseRoles = assignments => {
    const data = []

    assignments.forEach(assignment => {
      data.push({
        srn: assignment.srn,
        scope: assignment.scope,
        role: _.get(assignment, ['role', 'items', 0, 'name']),
        roleSrn: _.get(assignment, ['role', 'items', 0, 'srn']),
        description: _.get(assignment, ['role', 'items', 0, 'description']),
      })
    })
    return data
  }

  onPopoverToggle = newVisibility => {
    this.setState({
      menuOpen: newVisibility,
    })
  }

  resetPassword = srn => {
    this.props.resetPassword({ srn: srn })
  }

  toggleUserEnabled = srn => {
    this.props.updateSonraiUser({
      srn: srn,
      variables: { isActive: !this.props.users.getIn([srn, 'isActive']) },
      me: this.props.currentUserSrn === srn ? true : false,
    })
  }

  getActions = srn => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          columnGap: '0.2rem',
        }}
      >
        <div>
          {/* <WithPermission
            permissionName="edit.users"
            resourceId={this.props.users.getIn([srn, 'resourceId'])}
          >
            <Button
              title="Reset Password"
              onClick={() => {
                this.resetPassword(srn)
              }}
              style={this.styles.button}
              disabled={this.props.resettingPassword}
            >
              <div style={this.styles.actionButton}>
                <div style={{ textAlign: 'left' }}>
                  <FormattedMessage {...messages.ResetPassword} />
                </div>
                {this.props.resettingPassword ? (
                  <Icon fa name="sync" size="lg" spin />
                ) : (
                  <Icon
                    style={{ color: this.props.theme.info }}
                    fa
                    name="key"
                    size="lg"
                  />
                )}
              </div>
            </Button>
          </WithPermission> */}
        </div>
        <div>
          <WithPermission
            multiPermissions={[
              {
                permissionName: 'edit.users',
                resourceId: this.props.users.getIn([srn, 'resourceId']),
              },
              {
                permissionName: 'edit.roleassignments',
              },
            ]}
          >
            <Button
              title="Manage Roles"
              onClick={this.openRoleModal}
              style={this.styles.button}
            >
              <div style={this.styles.actionButton}>
                <div style={{ textAlign: 'left' }}>
                  <FormattedMessage {...messages.OpenModal} />
                </div>
                <Icon
                  style={{ color: this.props.theme.info }}
                  fa
                  name="key"
                  size="lg"
                />
              </div>
            </Button>
          </WithPermission>
        </div>
        <div>
          <WithPermission
            permissionName="edit.users"
            resourceId={this.props.users.getIn([srn, 'resourceId'])}
          >
            <Button
              onClick={() => {
                this.toggleUserEnabled(srn)
              }}
              style={this.styles.button}
              disabled={this.props.updatingSonraiUsers.get(srn)}
            >
              <div style={this.styles.actionButton}>
                <div style={{ textAlign: 'left' }}>
                  {this.props.users.getIn([srn, 'isActive']) ? (
                    <FormattedMessage {...messages.DisableUser} />
                  ) : (
                    <FormattedMessage {...messages.EnableUser} />
                  )}
                </div>
                {this.props.updatingSonraiUsers.get(srn) ? (
                  <Icon fa name="sync" spin size="lg" />
                ) : this.props.users.getIn([srn, 'isActive']) ? (
                  <Icon
                    style={{ color: this.props.theme.fail }}
                    fa
                    name="minus-circle"
                    size="lg"
                  />
                ) : (
                  <Icon
                    style={{ color: this.props.theme.success }}
                    fa
                    name="power-off"
                    size="lg"
                  />
                )}
              </div>
            </Button>
          </WithPermission>
        </div>
      </div>
    )
  }

  handleSelectionChange = event => {
    const rows = event.api.getSelectedRows() || []
    const selectedSrns = []
    rows.forEach(row => {
      selectedSrns.push(row.srn)
    })
    this.setState({ selectedSrns: fromJS(selectedSrns), selectedRows: rows })
  }

  unassignRoles = srn => {
    if (this.state.selectedSrns.toJS().length > 0) {
      this.props.updateUserRoles({
        srn: srn,
        remove: this.state.selectedSrns.toJS(),
      })
      this.setState({ selectedSrns: List(), selectedRows: [] })
      if (this.gridApi) {
        this.gridApi.deselectAll()
      }
    }
  }

  updateName = val => {
    const srn = qs.parse(this.props.location.search).srn
    this.props.updateSonraiUser({
      srn: srn,
      variables: { name: val },
      me: this.props.currentUserSrn === srn ? true : false,
    })
  }

  render() {
    const srn = qs.parse(this.props.location.search).srn
    const user = this.props.users.get(srn)
    if (this.props.loading || !user) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    const data = this.parseRoles(
      user.getIn(['roleAssignments', 'items'], List()).toJS()
    )

    const canAssignUsers = this.props.userHasPermission({
      permissionName: 'edit.roleassignments',
    })

    const canEditUser = this.props.userHasPermission({
      permissionName: 'edit.users',
      resourceId: user.get('resourceId'),
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.breadcrumbs}>
          {canEditUser && (
            <Breadcrumb>
              <BreadcrumbItem
                style={{ cursor: 'pointer', color: this.props.theme.primary }}
                onClick={() =>
                  this.props.push({ pathname: '/App/UserManagement' })
                }
              >
                All Users
              </BreadcrumbItem>
              <BreadcrumbItem> User Profile</BreadcrumbItem>
            </Breadcrumb>
          )}
        </div>
        <BorderedCard style={this.styles.header}>
          <div>
            <ProfileIcon
              width={'50px'}
              height={'50px'}
              img={user.get('avatarUrl')}
            />
          </div>
          <div>
            {canEditUser || srn === this.props.currentUserSrn ? (
              <Fragment>
                <InlineEditableText
                  value={user.get('name')}
                  onSubmit={this.updateName}
                  style={{ ...this.styles.input, ...this.styles.name }}
                  saving={this.props.updatingSonraiUsers.get(srn)}
                />
                <div style={{ ...this.styles.email, marginLeft: '9px' }}>
                  {user.get('email')}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div style={this.styles.name}>{user.get('name')}</div>
                <div style={this.styles.email}>{user.get('email')}</div>
              </Fragment>
            )}
          </div>
          <div>{this.getActions(srn)}</div>
        </BorderedCard>

        <BorderedCard>
          <SectionHeader>
            <FormattedMessage {...messages.RoleTitle} />
            {user.get('name')}
          </SectionHeader>
          {this.props.permissions.get('loading') ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SquareLoadingAnimation />
            </div>
          ) : (
            <DataTable
              onLoad={this.onTableLoad}
              checkboxSelectedRows={this.state.selectedRows}
              bulkActions={
                canEditUser
                  ? [
                      <ToolbarItem
                        permissionName="edit.users"
                        key="unassign"
                        onClick={() => {
                          this.unassignRoles(srn)
                        }}
                      >
                        <FormattedMessage {...messages.UnassignRole} />
                      </ToolbarItem>,
                    ]
                  : undefined
              }
              bulkActionWorking={
                canEditUser &&
                (this.props.unassigningRoles || this.props.updatingUserRoles)
              }
              hasCheckBoxes={canEditUser}
              autosize={false}
              data={data}
              selectionChanged={this.handleSelectionChange}
              customColumnConfig={{
                srn: { headerName: 'srn', field: 'srn', hide: 'true' },
                description: {
                  flex: 1,
                },
                roleSrn: { hide: true },
                scope: {
                  minWidth: 100,
                  cellRendererFramework: params => {
                    if (!params.value) {
                      return ''
                    }

                    return (
                      <FriendlyScope
                        scope={params.value}
                        roles={this.props.roles}
                        swimlanes={this.props.swimlanes}
                        roleSrn={params.data.roleSrn}
                      />
                    )
                  },
                },
              }}
            />
          )}
        </BorderedCard>
        {this.state.isModalOpen && (
          <AddEditInviteModal
            toggle={this.closeModal}
            isOpen={this.state.isModalOpen}
            user={user}
            existingUser={true}
            updatingUserInvite={this.props.updatingUserRoles}
            updatePendingRoleAssignments={this.props.updateUserRoles}
            restrictToSwimlanes={canAssignUsers && !canEditUser}
          />
        )}
        <Helmet title={`Sonrai - ${user.get('name') || user.get('email')}`} />
      </div>
    )
  }
}

UserProfileView.propTypes = {
  users: ImmutablePropTypes.map,
  permissions: ImmutablePropTypes.map,
  theme: themeShape,
  loading: PropTypes.bool,
  resettingPassword: PropTypes.bool,
  updatingUserRoles: PropTypes.bool,
  resetPassword: PropTypes.func,
  roles: ImmutablePropTypes.iterable.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  unassigningRoles: PropTypes.bool,
  updateUserRoles: PropTypes.func,
  updatingSonraiUsers: ImmutablePropTypes.map,
  updateSonraiUser: PropTypes.func,
  push: PropTypes.func,
  location: PropTypes.object,
  userHasPermission: PropTypes.func.isRequired,
  currentUserSrn: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
  loading: selectSonraiUsersLoading,
  resettingPassword: selectResettingPassword,
  togglingUser: selectTogglingUser,
  roles: selectAllRoles,
  swimlanes: selectSwimlanes,
  unassigningRoles: selectUnassigningRoles,
  updatingSonraiUsers: selectUpdatingSonraiUsers,
  updatingUserRoles: selectUpdatingUserRoles,
  currentUserSrn: selectSonraiUserSrn,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      updateUserRoles,
      updateSonraiUser,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withSaga = injectSaga({
  key: 'userProfileView',
  saga: userProfileViewSaga,
})

const withReducer = injectReducer({
  key: 'userProfileView',
  reducer: userProfileViewReducer,
})

export default compose(
  withReducer,
  permissionChecker,
  withSaga,
  withConnect,
  themeable
)(UserProfileView)
